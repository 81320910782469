import React from "react"

const GMail = () =>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12Z" fill="currentColor"/>
        <path d="M5 8C5 7.44772 5.44772 7 6 7H18C18.5523 7 19 7.44772 19 8V16C19 16.5523 18.5523 17 18 17H6C5.44772 17 5 16.5523 5 16V8Z" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M2.21069 5.38611C2.54976 4.95016 3.17804 4.87162 3.61399 5.21069L12 11.7332L20.3861 5.21069C20.8221 4.87162 21.4503 4.95016 21.7894 5.38611C22.1285 5.82205 22.0499 6.45033 21.614 6.7894L12.614 13.7894C12.2529 14.0703 11.7472 14.0703 11.3861 13.7894L2.38611 6.7894C1.95016 6.45033 1.87162 5.82205 2.21069 5.38611Z" fill="currentColor"/>
    </svg>

const Medium = () =>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM8.0767 17C10.8803 17 13.1532 14.7615 13.1532 12.0001C13.1532 9.2387 10.8805 7 8.0767 7C5.27291 7 3 9.2387 3 12.0001C3 14.7615 5.27308 17 8.0767 17ZM18.7226 11.9999C18.7226 14.5992 17.5861 16.7071 16.1842 16.7071C14.7823 16.7071 13.6459 14.5992 13.6459 11.9999C13.6459 9.4007 14.7822 7.29276 16.1841 7.29276C17.586 7.29276 18.7226 9.40003 18.7226 11.9999ZM20.1073 16.2173C20.6004 16.2173 21 14.3287 21 12.0004C21 9.67142 20.6002 7.78348 20.1073 7.78348C19.6144 7.78348 19.2147 9.67159 19.2147 12.0004C19.2147 14.3292 19.6142 16.2173 20.1073 16.2173Z" fill="currentColor"/>
    </svg>

const Dribbble = () =>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18.395 5.39026C19.1293 4.57196 19.5574 3.71521 19.7075 2.83575C17.6313 1.09717 14.9605 0 12.0469 0C10.5425 0 9.10791 0.291138 7.78076 0.800537C10.0031 3.0271 11.8616 5.66345 13.3153 8.50928C15.4573 7.73474 17.2426 6.67511 18.395 5.39026Z" fill="currentColor" />
        <path d="M19.4414 6.32971C18.1514 7.76746 16.2339 8.93677 13.9431 9.78656C14.2278 10.411 14.4895 11.0483 14.7358 11.694C15.9122 11.4409 17.1315 11.297 18.375 11.297C20.3326 11.297 22.2343 11.6292 23.9879 12.2396C23.9896 12.1592 24 12.0811 24 12.0001C24 8.90473 22.8073 6.08948 20.87 3.96436C20.5842 4.78796 20.1097 5.58502 19.4414 6.32971Z" fill="currentColor" />
        <path d="M0.27417 9.47412C1.76886 9.73834 3.42487 9.89069 5.01569 9.89069C7.48175 9.89069 9.85059 9.54059 11.9618 8.93927C10.507 6.14417 8.63678 3.57172 6.40454 1.42041C3.36664 3.05426 1.02271 6.01068 0.27417 9.47412Z" fill="currentColor" />
        <path d="M23.8666 13.6873C22.1703 13.0488 20.3033 12.7031 18.3749 12.7031C17.2938 12.7031 16.2322 12.8165 15.2083 13.0234C16.2477 16.1407 16.8492 19.5103 16.952 22.9352C20.6089 21.2809 23.2821 17.7962 23.8666 13.6873Z" fill="currentColor" />
        <path d="M0 12.0002C0 15.6982 1.78253 19.0554 4.42841 21.2496C4.70874 19.3846 5.54956 17.5499 6.91901 16.0144C8.54791 14.1881 10.8083 12.8205 13.3682 12.0399C13.1299 11.4254 12.8698 10.823 12.5951 10.229C10.3077 10.9074 7.73145 11.2971 5.01562 11.2971C3.35413 11.2971 1.62506 11.1383 0.0576782 10.8629C0.0221558 11.2377 0 11.6164 0 12.0002H0Z" fill="currentColor" />
        <path d="M7.96893 16.95C6.58557 18.5013 5.83612 20.3206 5.73999 22.1819C7.57343 23.3268 9.73059 23.9999 12.0469 23.9999C13.2647 23.9999 14.4397 23.8146 15.5477 23.4746C15.4938 19.9736 14.9061 16.5312 13.8375 13.3628C11.4983 14.0643 9.44073 15.2997 7.96893 16.95Z" fill="currentColor" />
    </svg>

const LinkedIn = () =>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 0C5.3736 0 0 5.3736 0 12C0 18.6264 5.3736 24 12 24C18.6264 24 24 18.6264 24 12C24 5.3736 18.6264 0 12 0ZM8.51294 18.1406H5.59039V9.34808H8.51294V18.1406ZM7.05176 8.14746H7.03271C6.052 8.14746 5.41772 7.47235 5.41772 6.6286C5.41772 5.76581 6.07141 5.10938 7.07117 5.10938C8.07092 5.10938 8.68616 5.76581 8.7052 6.6286C8.7052 7.47235 8.07092 8.14746 7.05176 8.14746ZM19.051 18.1406H16.1288V13.4368C16.1288 12.2547 15.7057 11.4485 14.6483 11.4485C13.8409 11.4485 13.3601 11.9923 13.1488 12.5173C13.0715 12.7051 13.0527 12.9677 13.0527 13.2305V18.1406H10.1303C10.1303 18.1406 10.1686 10.173 10.1303 9.34808H13.0527V10.593C13.441 9.9939 14.1359 9.14172 15.6865 9.14172C17.6093 9.14172 19.051 10.3984 19.051 13.099V18.1406Z" fill="currentColor" />
    </svg>

const Resume = () =>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="12" cy="12" r="12" fill="currentColor"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M16.6316 13.6556C17.0572 14.1494 17.002 14.8947 16.5083 15.3203L12.705 18.599C12.2678 18.9759 11.6222 18.9813 11.1787 18.6118L7.24428 15.3331C6.7435 14.9157 6.67584 14.1715 7.09316 13.6707C7.51048 13.1699 8.25475 13.1022 8.75554 13.5196L10.754 15.1849L10.754 6.03287C10.754 5.38099 11.2825 4.85254 11.9343 4.85254C12.5862 4.85254 13.1147 5.38099 13.1147 6.03287L13.1147 15.1291L14.9669 13.5323C15.4607 13.1067 16.206 13.1619 16.6316 13.6556Z" fill="white"/>
    </svg>

const Footer = () => {
    return (
        <footer className="mt-16 mb-32 flex justify-center items-center">
            <a className="px-8 hover:text-au_chico" href="mailto://lynnmariebee@gmail.com">
                <GMail />
            </a>
            <a className="px-8 hover:text-au_chico" href="https://lynnbaxter.medium.com">
                <Medium />
            </a>
            <a className="px-8 hover:text-au_chico" href="https://dribbble.com/lynnb">
                <Dribbble />
            </a>
            <a className="px-8 hover:text-au_chico" href="https://www.linkedin.com/in/lynnmariebaxter">
                <LinkedIn />
            </a>
            <a className="px-8 hover:text-au_chico" href="/Lynn Baxter Résumé.pdf">
                <Resume />
            </a>
        </footer >
    )
}
export default Footer