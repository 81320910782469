import { Link, graphql, useStaticQuery } from "gatsby"
import React from "react"
import Img from 'gatsby-image'

const Menu = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "nav-icon.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const [visible, setVisible] = React.useState(false);

  const nonMobile = (
    <div className="hidden sm:block sm:h-full">
      <ul className="flex items-end h-full">
        <li className="ml-12 leading-6"><Link activeClassName="text-au_chico" to="/trash-panda">Trash Panda</Link></li>
        <li className="ml-12 leading-6"><Link activeClassName="text-au_chico" to="/plant-parenthood">Plant Parenthood</Link></li>
        <li className="ml-12 leading-6"><Link activeClassName="text-au_chico" to="/nextbook">Nextbook</Link></li>
        <li className="ml-12 leading-6"><Link activeClassName="text-au_chico" to="/about">About Me</Link></li>
      </ul>
    </div>
  )

  if (visible) {
    return (
      <nav>
        {nonMobile}
        <div className="sm:hidden pt-2">
          <div className="text-right">
            <button style={{ width: "1rem" }} onClick={() => setVisible(false)}>
              <Img fluid={data.file.childImageSharp.fluid} />
            </button>
          </div>
          <ul className="text-nav1">
            <li className="my-2"><Link activeClassName="text-au_chico" to="/trash-panda">Trash Panda</Link></li>
            <li className="my-2"><Link activeClassName="text-au_chico" to="/plant-parenthood">Plant Parenthood</Link></li>
            <li className="my-2"><Link activeClassName="text-au_chico" to="/nextbook">Nextbook</Link></li>
          </ul>
          <div className="my-2 text-nav1"><Link activeClassName="text-au_chico" to="/about">About Me</Link></div>
        </div>
      </nav>
    );
  } else {
    return (
      <nav>
        {nonMobile}
        <div className="sm:hidden pt-2">
          <button style={{ width: "1rem" }} onClick={() => setVisible(true)}>
            <Img fluid={data.file.childImageSharp.fluid} />
          </button>
        </div>
      </nav>
    );
  }
}

const Header = ({ siteTitle, data }) => {
  return (
    <header className="my-4 flex justify-between sm:mx-4">
      <Link to="/" className="text-nav1 font-bold font-serif">
        {siteTitle}
      </Link>
      <Menu />
    </header >
  )
}
export default Header
